import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";


function WebsiteBanner({desktopImg, mobileImg, altText, link}){
  return (
    <>
      <Row className="py-4 py-lg-5">
        <Col className="px-lg-0">
          { link ? 
            <>
              <Link to={link} className="d-none d-md-block">
                <img 
                  src={`https://web-cdn.meridianuniversity.edu/site-content-images/${desktopImg}`}
                  alt={altText}
                  className="img-fluid d-none d-md-block"
                />
              </Link>
              <Link to={link} className="d-md-none">
                <img
                  src={`https://web-cdn.meridianuniversity.edu/site-content-images/${mobileImg}`}
                  alt={altText}
                  className="img-fluid d-md-none"
                />
              </Link>
            </>
          :
            <>
              <img 
                src={`https://web-cdn.meridianuniversity.edu/site-content-images/${desktopImg}`}
                alt={altText}
                className="img-fluid d-none d-md-block"
              />
              <img
                src={`https://web-cdn.meridianuniversity.edu/site-content-images/${mobileImg}`}
                alt={altText}
                className="img-fluid d-md-none"
              />      
            </>        
          }
          
        </Col>
      </Row>
    </>
  )
}


function Celebrating30Years(){
  return (
    <>
      <Row className="py-4 py-lg-5">
        <Col className="px-lg-0">
          <Link to="/discover/hybrid" className="d-none d-md-block">
            <img 
              src="https://web-cdn.meridianuniversity.edu/site-content-images/banners/hybrid-programs-banner-v2.webp"
              alt="Hybrid Learning Programs at Meridian University"
              className="img-fluid d-none d-md-block mx-auto"
            />
          </Link>
          <Link to="/discover/hybrid" className="d-md-none">
            <img
              src="https://web-cdn.meridianuniversity.edu/site-content-images/banners/hybrid-programs-banner-mobile-v2.webp"
              alt="Hybrid Learning Programs at Meridian University"
              className="img-fluid d-md-none mx-auto"
            />
          </Link>
        </Col>
      </Row>
    </>
  );
};



export { Celebrating30Years, WebsiteBanner };
