import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SSRProvider from "react-bootstrap/SSRProvider";

import DefaultHeader from "../../components/default-header";
import Footer from "../../components/footer";
import GdprPanel from "../../components/gdpr";
import Navigation from "../../components/navigation";

import Seo from "../../components/seo";
import NewsletterSignup from "../../components/newsletterSignUp";

import "../../styles/quickLinks.scss"

const Page = ({ data }) => {

   const shortcodes = {
      Container,
      Row,
      Col,
      Button
    };

  return (
    <>
      <SSRProvider>
        <Navigation></Navigation>
        <DefaultHeader
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        ></DefaultHeader>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </MDXProvider>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query QuickLinksPageQuery {
    mdx(fileAbsolutePath: { regex: "/quick-links.mdx/" }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;

export default Page;
