import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const InPersonInfoSession = () => {

    const urlRef = typeof window !== "undefined" ? window.location.search : "";
    const url = urlRef.slice(1);
    const urlParams = new URLSearchParams(url);
    const cta = urlParams.has("cta") ? urlParams.get("cta") : "basic";


    return (
        cta === "ois" ?
        <div id="ISSignUp" className="my-md-5 my-4 px-2 px-lg-0 pb-md-5 pb-4">
            <Row className="justify-content-between align-items-center p-4 green-bg rounded white-text">
                <Col xs={12} md={8} className="white-text">
                    <h2 className="mb-4 white-text" style={{ fontSize: 32 }}>Ready to Explore the LA Campus?</h2>
                    <p className="white-text" style={{opacity: 1}}>Learn more about Meridian’s hybrid programs by attending an in-person information session at the Los Angeles Campus. Meet faculty and connect with admissions advisors to discuss your academic goals and application next steps.</p>
                    <p className="white-text" style={{opacity: 1}}>WEDNESDAY, JANUARY 29, 12 P.M. </p>
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <a href="#mu-hero" className="btn btn-white">Register for the Information Session{" "}
                    <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                        fill="#0078ae"
                        />
                    </svg>
                    </a>
                </Col>
            </Row>
        </div>
        :
        null
    )
}

export default InPersonInfoSession