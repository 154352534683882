import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StripeRegisterForm from "../forms/stripeRegisterFormTLL";
import { WorkshopNewsletterForm } from "../forms/workshopNewsletterForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "../styles/stripeRegister.scss";

const stripePromise = loadStripe("pk_live_zxPVztFFG5bxRLdq5EUB3qqx");

function WorkshopRegistration(pros) {
  return (
    <div id="tllRegistration" className="py-4 py-xl-5 medium-light-gray-bg">
      <Container fluid="lg" className="py-4 py-xl-5">
        <Row className="justify-content-center align-items-center mb-xl-5 pb-3">
          <Col xl={8} md={10} xs={12} className="order-lg-last text-center">
            <h2 className="pb-4 mb-1">
              Register to participate in an upcoming Transformative Learning Lab
            </h2>
            <p className="reg-text pb-3 mb-0">
              Led by a diverse collective of experts and change-makers as
              Contributing Faculty, Transformative Learning Labs are unique
              onsite events. Pre-registration is mandatory due to limited
              seating and to maintain an advanced learning environment.
              Registrations are screened for admission in the order they're
              received, and late sign-ups might miss out on assured
              participation.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="p-xl-5 p-4 white-bg">
            <Row>
              <Col
                xs={12}
                md={5}
                lg={4}
                className="pe-xl-5 pe-md-4 mb-4 mb-lg-0"
              >
                <img
                  src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-registration-discount-image.webp"
                  className="img-fluid rounded"
                  alt="absctract of earth curvature from outspace"
                />
                <div className="discount-block p-2 mb-n4">
                  <p className="mb-0">
                    A 50% discount is available for Meridian alumni.
                  </p>
                  <div className="contact-block">
                    <Row>
                      <Col xs={1} className="px-0 text-center">
                        <img
                          src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-contact-icon.svg"
                          alt="contact icon"
                          className="img-fluid"
                        />
                      </Col>
                      <Col xs={11} className="ps-0">
                        <p>
                          <i>
                            Contact{" "}
                            <a href="mailto:ctl@meridianuniversity.edu">
                              ctl@meridianuniversity.edu
                            </a>{" "}
                            for discount details.
                          </i>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={7} className="ms-xl-5 tll-form">
                <>{/*<p>
                  Current Meridian students can participate in certain
                  Transformative Learning Labs without paying fees. Students
                  should contact the Student Services Team for registration
                  information.
                </p>
                <Elements stripe={stripePromise}>
                  <StripeRegisterForm
                    form={pros.form}
                    submitCampaignIDBerlin={pros.submitCampaignIDBerlin}
                    submitType={pros.submitType}
                    berlinPriceId={pros.berlinPriceId}
                  />
                </Elements>*/}</>                  
                  <WorkshopNewsletterForm submitCampaignID={pros.submitCampaignID} submitType={pros.submitType} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WorkshopRegistration;
