import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DiscoverContactForm from "./discoverContactForm";
import DiscoverImpactForm from "./discoverImpactForm";
import DiscoverOISRegistrationForm from "./discoverOISRegistrationForm";
import DiscoverHybridForm from "./discoverHybridForm";

import "./styles/default-header.scss";
import "./styles/discover-header.scss";

const DiscoverHeader = ({
  headline,
  headlineOIS,
  heroBackground,
  heroBackgroundOIS,
  subHeadline,
  subHeadlineOIS,
  menu,
  header,
}) => {
  const [ready, setReady] = useState(null);
  useEffect(() => {
    setReady(true);
  }, []);
 
  /* Get URL Params */
  const urlRef = typeof window !== "undefined" ? window.location.search : "";
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const cta = urlParams.has("cta") ? urlParams.get("cta") : "basic";
  let cta_headline;
  let cta_text;
  let form;
  let program;
  let campaignId;

  if (menu === "MBA") {
    program = "Business";
    campaignId = "7013i000000QofTAAS";
  }
  if (menu === "EDU") {
    program = "Education";
    campaignId = "7013i000000QofOAAS";
  }
  if (menu === "PSYCH") {
    program = "Psychology";
    campaignId = "7013i000000QofJAAS";
  }
  if (menu === "ALL") {
    program = "Meridian";
    campaignId = "7013i000000QoglAAC";
  }

  if (cta === "ebook" || cta === "pdf") {
    cta_headline = (
      <>
        <Col xs={2} className="me-n5 mb-2">
          <img
            src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/form-pdf-icon.svg"
            width="50"
            height="50"
            alt="Meridian Form PDF Icon"
          />
        </Col>
        <Col xs={10} className="ps-0">
          <h5>Ready to receive a detailed guide?</h5>
        </Col>
      </>
    );
    cta_text = (
      <>
        <p className="mb-0">
          Request the {program} Program Overview PDF with details on learning
          formats, curriculum and requirements, alumni outcomes, and more.
        </p>
      </>
    );
    form = (
      <DiscoverContactForm
        submitCampaignID={campaignId}
        submitType="lead"
        program={menu}
        cta={cta}
      />
    );
  } else if ((cta === "ois") && (header !== "hybrid") ) {
    cta_headline = (
      <>
        <Col xs={2} className="me-n5 mb-2">
          <img
            src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/form-pdf-icon.svg"
            width="50"
            height="50"
            alt="Meridian Form PDF Icon"
          />
        </Col>
        <Col xs={10} className="ps-0">
          <h5>Ready to connect with program faculty?</h5>
        </Col>
      </>
    );
    cta_text = (
      <>
        <p className="mb-0">
          Meridian faculty are hosting a live, interactive Online Information
          Session for the {program} programs soon. Discuss your professional
          goals and academic directly with faculty. Space is limited in each
          session.
        </p>
      </>
    );
    form = <DiscoverOISRegistrationForm submitType="lead" programType={menu} />;
  } else if ((header === "impact-mba")) {
    cta_headline = (
      <>
        <Col>
          <h5>
            Apply for Meridian University's Social Entrepreneurship Scholarship
          </h5>
        </Col>
      </>
    );
    cta_text = (
      <>
        <p className="mb-0">
          Make a difference in the world and receive financial support while
          earning a MBA in Creative Enterprise from Meridian University.
        </p>
      </>
    );
    form = (
      <DiscoverImpactForm
        submitCampaignID="701Do000000kvGLIAY"
        submitType="lead"
      />
    );
  } else if ((header === "hybrid")) {
    if((cta === "ois")) {
      cta_headline = (
        <>
          <Col xs={2} className="me-n5 mb-2">
            <img
              src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/icons/form-pdf-icon.svg"
              width="50"
              height="50"
              alt="Meridian Form PDF Icon"
            />
          </Col>
          <Col xs={10} className="ps-0">
            <h5>Ready to connect with program faculty?</h5>
          </Col>
        </>
      );
      cta_text = (
        <>
          <p className="mb-0">
          Join Meridian University’s faculty and admissions advisors for an in-person information session at the Los Angeles Campus. Ask questions and discover how our hybrid programs blend online and in-person learning to support your transformative journey of personal and professional growth.
          </p>
        </>
      );
      form = (
        <DiscoverHybridForm
          submitCampaignID="701UZ00000OHtAPYA1"
          submitType="lead"
          program={menu}
          cta={cta}
        />
      );
    } else {
      cta_headline = (
        <>
          <Col>
            <h5>Discover Your Professional Future at Meridian</h5>
          </Col>
        </>
      );
      cta_text = (
        <>
          <p className="mb-0">
            Connect with Admissions to learn more about curriculum, programs,
            upcoming faculty-led information sessions, and how Meridian can
            support your transformative learning journey.
          </p>
        </>
      );
      form = (
        <DiscoverHybridForm
          submitCampaignID="701UZ00000KI6zfYAD"
          submitType="lead"
          program={menu}
        />
      );
    }
  } else {
    cta_headline = (
      <>
        <Col>
          <h5>Discover Your Professional Future at Meridian</h5>
        </Col>
      </>
    );
    cta_text = (
      <>
        <p className="mb-0">
          Connect with Admissions to learn more about curriculum, programs,
          upcoming faculty-led information sessions, and how Meridian can
          support your transformative learning journey.
        </p>
      </>
    );
    form = (
      <DiscoverContactForm
        submitCampaignID="7013i000000qH4WAAU"
        submitType="lead"
        program={menu}
      />
    );
  }

  return (
    ready && (
      <div>
        {header === "specific" ? (
          <div id="discoverHero">
            <div id="mu-hero" style={{ "--img": heroBackground }}>
              <Container fluid="lg">
                <Row className="justify-content-xl-between justify-content-center py-5">
                  <Col
                    xl={6}
                    lg={8}
                    md={10}
                    xs={12}
                    className="pe-xl-5 mb-4 pb-2 mb-md-5 pb-md-4 pb-xl-0 mb-xl-0 text-center text-xl-start"
                  >
                    <h1 className="mu-hero-headline mb-2">{headline}</h1>
                    <h3 className="mu-hero-subheadline">{subHeadline}</h3>
                  </Col>
                  <Col
                    xl={6}
                    lg={8}
                    sm={10}
                    xs={11}
                    id="lpForm"
                    className="p-4"
                  >
                    <Row className="align-items-center mb-1">
                      {cta_headline}
                    </Row>
                    <Row className="mb-3">
                      <Col>{cta_text}</Col>
                    </Row>
                    <Row>{form}</Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        ) : header === "impact-mba" ? (
            <div id="discoverHero">
              <div id="mu-hero" style={{ "--img": heroBackground }}>
                <Container fluid="lg">
                  <Row className="justify-content-xl-between justify-content-center py-5">
                    <Col 
                      xl={6}
                      lg={8}
                      md={10}
                      xs={12}
                      className="pe-xl-5 mb-4 pb-2 mb-md-5 pb-md-4 pb-xl-0 mb-xl-0 text-center text-xl-start align-self-center"
                    >
                      <h1 className="mu-hero-headline mb-2">
                        {headline.split("\n").map(function (item, key) {
                          return <span key={key++}>{item}</span>;
                        })}
                      </h1>
                      <h3 className="mu-hero-subheadline">{subHeadline}</h3>
                    </Col>
                    <Col
                      xl={6}
                      lg={8}
                      sm={10}
                      xs={11}
                      id="lpForm"
                      className="p-4"
                    >
                      <Row className="align-items-center mb-1">
                        {cta_headline}
                      </Row>
                      <Row className="mb-3">
                        <Col>{cta_text}</Col>
                      </Row>
                      <Row>{form}</Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
        ) : header === "hybrid" ? (
          cta === "ois" ? (
            <div id="discoverHero">
              <div id="mu-hero" class="ois" style={{ "--img": heroBackgroundOIS }}>
                <Container fluid="lg">
                  <Row className="align-items-center justify-content-xl-between justify-content-center py-5">
                    <Col
                      xl={6}
                      lg={8}
                      md={10}
                      xs={12}
                      className="pe-xl-5 mb-4 pb-2 mb-md-5 pb-md-4 pb-xl-0 mb-xl-0 text-center text-xl-start"
                    >
                      <h1 className="mu-hero-headline mb-2">{headlineOIS.split("\n").map(function (item, key) {
                          return <span key={key++}>{item}</span>;
                        })}</h1>
                      <h3 className="mu-hero-subheadline">{subHeadlineOIS.split("\n").map(function (item, key) {
                          return <span key={key++}>{item}</span>;
                        })}</h3>
                    </Col>
                    <Col
                      xl={6}
                      lg={8}
                      sm={10}
                      xs={11}
                      id="lpForm"
                      className="p-4"
                    >
                      <Row className="align-items-center mb-1">
                        {cta_headline}
                      </Row>
                      <Row className="mb-3">
                        <Col>{cta_text}</Col>
                      </Row>
                      <Row>{form}</Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          ) : (
            <div id="discoverHero">
              <div id="mu-hero" style={{ "--img": heroBackground }}>
                <Container fluid="lg">
                  <Row className="align-items-center justify-content-xl-between justify-content-center py-5">
                    <Col
                      xl={6}
                      lg={8}
                      md={10}
                      xs={12}
                      className="pe-xl-5 mb-4 pb-2 mb-md-5 pb-md-4 pb-xl-0 mb-xl-0 text-center text-xl-start"
                    >
                      <h1 className="mu-hero-headline mb-2">{headline}</h1>
                      <h3 className="mu-hero-subheadline">{subHeadline}</h3>
                    </Col>
                    <Col
                      xl={6}
                      lg={8}
                      sm={10}
                      xs={11}
                      id="lpForm"
                      className="p-4"
                    >
                      <Row className="align-items-center mb-1">
                        {cta_headline}
                      </Row>
                      <Row className="mb-3">
                        <Col>{cta_text}</Col>
                      </Row>
                      <Row>{form}</Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )        
        ) :
        (
          <div id="discoverHero">
            <div id="mu-hero" style={{ "--img": heroBackground }}>
              <Container fluid="lg">
                <Row className="align-items-center justify-content-xl-between justify-content-center py-5">
                  <Col
                    xl={6}
                    lg={8}
                    md={10}
                    xs={12}
                    className="pe-xl-5 mb-4 pb-2 mb-md-5 pb-md-4 pb-xl-0 mb-xl-0 text-center text-xl-start"
                  >
                    <h1 className="mu-hero-headline mb-2">{headline}</h1>
                    <h3 className="mu-hero-subheadline">{subHeadline}</h3>
                  </Col>
                  <Col
                    xl={6}
                    lg={8}
                    sm={10}
                    xs={11}
                    id="lpForm"
                    className="p-4"
                  >
                    <Row className="align-items-center mb-1">
                      {cta_headline}
                    </Row>
                    <Row className="mb-3">
                      <Col>{cta_text}</Col>
                    </Row>
                    <Row>{form}</Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      </div>
    )
  );
};
export default DiscoverHeader;
